import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover, Divider, Tag } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import numberWithCommas from "util/numberWithCommas";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
  const { authUser, token } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          history.push("/my-account/account-settings");
        }}
      >
        <IntlMessages id="sidebar.profile.dropdown.accountSettings" />
      </li>
      <li onClick={() => dispatch(userSignOut(token))}>
        <IntlMessages id="sidebar.profile.dropdown.logout" />
      </li>
    </ul>
  );

  const RoleBadge = () => {
    switch (authUser.rank) {
      case "user":
        return (
          <Tag color="#108ee9">
            <IntlMessages id="sidebar.profile.rank.user" />
          </Tag>
        );
      case "kingdom leadership":
        return (
          <Tag color="#fc0303">
            <IntlMessages id="sidebar.profile.rank.kingdomLeadership" />
          </Tag>
        );
      case "admin":
        return (
          <Tag color="#d000ff">
            <IntlMessages id="sidebar.profile.rank.admin" />
          </Tag>
        );
      default:
        return (
          <Tag color="#000000">
            <IntlMessages id="sidebar.profile.rank.unknown" />
          </Tag>
        );
    }
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          className="gx-size-40 gx-pointer gx-mr-3"
        >
          {authUser.username[0].toUpperCase()}
        </Avatar>
        <span className="gx-avatar-name">
          {authUser.username} <RoleBadge></RoleBadge>
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
      <Divider style={{ color: "white" }}>
        <span className="gx-avatar-name">
          <IntlMessages id="sidebar.profile.kingdomPoints" />:{" "}
          {numberWithCommas(authUser.balance)}
        </span>
      </Divider>
    </div>
  );
};

export default UserProfile;
