import {
  BID_RECEIVED,
  LOAD_HISTORY,
  BIDDING_STARTED,
  SALE_COMPLETED,
  CONNECTED,
  DISCONNECTED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  bids: [],
  items: {},
  connected: false,
};

let tempItems;

const biddingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BID_RECEIVED:
      let bids = [...state.bids];
      if (bids.length >= 5) {
        bids.pop();
      }
      tempItems = state.items;
      tempItems[action.payload.itemId] = {
        amount: action.payload.amount,
        username: action.payload.username,
        timer: action.payload.timer,
        timestamp: action.payload.timestamp,
        state: "live",
      };
      return { ...state, bids: [action.payload, ...bids], items: tempItems };
    case LOAD_HISTORY:
      return {
        ...state,
        bids: action.payload.bids,
        items: action.payload.items,
      };
    case BIDDING_STARTED:
      tempItems = state.items;
      tempItems[action.payload.itemId] = {
        amount: action.payload.start_price,
        username: "server",
        timer: action.payload.timer,
        state: "live",
      };
      return { ...state, items: tempItems };
    case SALE_COMPLETED:
      tempItems = state.items;
      tempItems[action.payload.itemId].state = "finished";
      return { ...state, items: tempItems };
    case CONNECTED:
      return { ...state, connected: true };
    case DISCONNECTED:
      return { ...state, connected: false };
    default:
      return state;
  }
};

export default biddingReducer;
