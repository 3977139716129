import messages from "../messages";

let token = localStorage.getItem("token");

const jsonMessage = {
  type: messages.AUTHENTICATION,
  access_token: "Bearer " + token,
};
const handleOpen = (mySocket) => {
  console.log("sending...");
  mySocket.send(JSON.stringify(jsonMessage));
  console.log("sent!");
};

export default handleOpen;
