import React, { useEffect, useState } from "react";
import HandleMessage from "./events/message";
import handleOpen from "./events/open";
import {useDispatch} from "react-redux"
import { setConnected, setDisconnected } from "../../../appRedux/actions";

let biddingSocket;
let isOpen = false;

const useBiddingSocket = () => {
  if (isOpen) {
    return biddingSocket;
  } else {
    return null;
  }
};

const exportIsOpen = () =>{
  return isOpen;
}

const BiddingWebsocketConnect = () => {
  const [eventData, setEventData] = useState("{}");
  const dispatch = useDispatch();

  const authorizeSocket = () => {
    biddingSocket.onopen = () => {
      isOpen = true;
      handleOpen(biddingSocket);
      socketOnMessage();
      socketOnClose();
      dispatch(setConnected())
    };
  };

  const socketOnMessage = () => {
    biddingSocket.onmessage = (event) => {
      setEventData(event.data);
    };
  };
  
  const socketOnClose = () => {
    biddingSocket.onclose = () => {
      isOpen = false;
      dispatch(setDisconnected())
    };
  };

  useEffect(() => {
    biddingSocket = new WebSocket(
      "wss://rok.usa.xtreamdutch-fighters.be:8082/?type=user"
    );
    authorizeSocket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>
      <HandleMessage
        setEventData={setEventData}
        event={eventData}
      ></HandleMessage>
    </div>
  );
};

export { BiddingWebsocketConnect, biddingSocket, useBiddingSocket, exportIsOpen };
