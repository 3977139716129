import {
  BID_RECEIVED,
  LOAD_HISTORY,
  BIDDING_STARTED,
  SALE_COMPLETED,
  CONNECTED,
  DISCONNECTED
} from "../../constants/ActionTypes";

export const addBid = ({ data }) => {
  return (dispatch) => {
    dispatch({ type: BID_RECEIVED, payload: data });
  };
};

export const loadHistory = ({ data }) => {
  return (dispatch) => {
    dispatch({ type: LOAD_HISTORY, payload: data });
  };
};

export const startBidding = ({ data }) => {
  return (dispatch) => {
    dispatch({ type: BIDDING_STARTED, payload: data });
  };
};

export const saleCompleted = ({ data }) => {
  return (dispatch) => {
    dispatch({ type: SALE_COMPLETED, payload: data });
  };
};

export const setConnected = () => {
  return (dispatch) => {
    dispatch({ type: CONNECTED, payload: true });
  };
};

export const setDisconnected = () => {
  return (dispatch) => {
    dispatch({ type: DISCONNECTED, payload: true });
  };
};