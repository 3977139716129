const messages = Object.freeze({
  ERROR: 'ERROR',
  AUTHENTICATION: 'AUTHENTICATION',
  CREATE_BID: 'CREATE_BID',
  OUTBID: 'OUTBID',
  SALE_COMPLETED: 'SALE_COMPLETED',
  BID_CREATED: 'BID_CREATED',
  HISTORY: 'HISTORY',
  BIDDING_STARTED: 'BIDDING_STARTED',
  NO_BUYER: 'NO_BUYER',
  VIRTUAL_BALANCE_UPDATE: 'VIRTUAL_BALANCE_UPDATE',
  WINNER: "WINNER"
})

module.exports = messages;
