import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSound from "use-sound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faCommentDollar,
  faExclamationCircle,
  faGavel,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { faFrownOpen } from "@fortawesome/free-regular-svg-icons";
import { notification } from "antd";

import message from "../messages";
import errors from "./../errors";
import numberWithCommas from "util/numberWithCommas";
import {
  addBid,
  startBidding,
  loadHistory,
  saleCompleted,
  getUser,
} from "../../../../appRedux/actions";
import pingSfx from "./../sounds/notification.mp3";

const HandleMessage = (props) => {
  const dispatch = useDispatch();
  const [play] = useSound(pingSfx, { volume: 0.5 });

  useEffect(() => {
    const messageObject = JSON.parse(props.event);
    props.setEventData("{}");

    console.log("Bidding websocket event", messageObject);

    switch (messageObject.type) {
      case message.BID_CREATED:
        dispatch(addBid(messageObject));
        console.log("Received Bid Created Event", messageObject);
        notification.open({
          message: "New Bid!",
          description: `User ${
            messageObject.data.username
          } has bid ${numberWithCommas(messageObject.data.amount)} for ${
            messageObject.data.itemName
          }!`,
          icon: (
            <FontAwesomeIcon
              icon={faCommentDollar}
              style={{ color: "green" }}
            />
          ),
        });
        break;

      case message.WINNER:
        console.log("Received Winner Event", messageObject);
        dispatch(getUser());
        notification.open({
          message: "You won the bid!",
          description: `The item ${messageObject.data.itemName} was won by you!`,
          icon: (
            <FontAwesomeIcon
              icon={faGift}
              style={{ color: "green" }}
            />
          ),
        });
        break;

      case message.SALE_COMPLETED:
        console.log("Received Sale completed Event", messageObject);
        dispatch(saleCompleted(messageObject));
        if (messageObject.username !== "") {
          notification.open({
            message: "Sale Completed!",
            description: `The item ${messageObject.data.itemName} was sold to ${
              messageObject.data.username
            } for ${numberWithCommas(messageObject.data.amount)}`,
            icon: <FontAwesomeIcon icon={faCoins} style={{ color: "green" }} />,
          });
        } else {
          notification.open({
            message: "Sale Failed!",
            description: `The item ${messageObject.data.itemName} was not bought by anyone`,
            icon: (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "red" }}
              />
            ),
          });
        }

        break;

      case message.BIDDING_STARTED:
        dispatch(startBidding(messageObject));
        console.log("Received Bidding Started event", messageObject);
        notification.open({
          message: "Bidding Started!",
          description: `The item ${messageObject.data.itemName} is now available for bidding!`,
          icon: <FontAwesomeIcon icon={faGavel} style={{ color: "green" }} />,
        });

        // Play sound
        play();
        break;

      case message.ERROR:
        console.log("Received Bidding Error", messageObject);
        let title = "";
        let description = "";

        switch (messageObject.error_type) {
          case errors.OUT_OF_BALANCE:
            title = "Insufficient Balance!";
            description =
              "You do not have enough balance to compelte this bid!";
            break;

          case errors.SYSTEM_ERROR:
            title = "System Error!";
            description = "The system has encountered an error!";
            break;

          case errors.DATABASE_ERROR:
            title = "System Error!";
            description = "The system has encountered an error!";
            break;

          case errors.BID_OPEN_ERROR:
            title = "Bid Error!";
            description = "Bidding is not yet available for this item!";
            break;

          case errors.BID_AMOUNT_ERROR:
            title = "Bid Error!";
            description = "Your bid amount was too low!";
            break;

          case errors.SALE_ERROR:
            title = "Sale Error!";
            description = "Unkown Item Type!";
            break;

          case errors.WS_ERROR:
            title = "System Error!";
            description = "The system has encountered an error!";
            break;

          case errors.JSON_ERROR:
            title = "System Error!";
            description = "The system has encountered an error!";
            break;

          case errors.UNAUTHENTICATED:
            title = "System Error!";
            description = "The system has encountered an error!";
            break;

          case errors.UNKNOWN_ITEM:
            title = "System Error!";
            description = "The system has encountered an error!";
            break;

          case errors.BID_NOT_ALLOWED:
            title = "Bid Error";
            description = "You are not allowed to bid on this item!";
            break;

          default:
            title = "Unknown Error";
            description = "Please contact an administrator!";
        }
        notification.open({
          message: `${title}`,
          description: `${description}`,
          icon: <FontAwesomeIcon icon={faFrownOpen} style={{ color: "red" }} />,
        });
        break;

      case message.HISTORY:
        dispatch(loadHistory(messageObject));
        break;

      default:
        console.log("Unknown Message received", messageObject);
    }
  }, [props.event]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div></div>;
};

export default HandleMessage;
