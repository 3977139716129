import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}my-account/profile`}
          component={asyncComponent(() => import("./MyAccount/Profile"))}
        />
        <Route
          path={`${match.url}my-account/account-settings`}
          component={asyncComponent(() =>
            import("./MyAccount/AccountSettings")
          )}
        />
        <Route
          path={`${match.url}my-account/groups`}
          component={asyncComponent(() => import("./MyAccount/Groups"))}
        />
        <Route
          path={`${match.url}my-kingdom/dashboard`}
          component={asyncComponent(() => import("./MyKingdom/Dashboard"))}
        />
        <Route
          path={`${match.url}my-kingdom/kvk-dashboard`}
          component={asyncComponent(() => import("./MyKingdom/KVKDashboard"))}
        />
        <Route
          path={`${match.url}my-kingdom/metrics`}
          component={asyncComponent(() => import("./MyKingdom/Metrics"))}
        />
        <Route
          path={`${match.url}my-kingdom/kingdom-points/store-management`}
          component={asyncComponent(() =>
            import("./MyKingdom/KingdomPoints/StoreManagement")
          )}
        />
        <Route
          path={`${match.url}my-kingdom/kingdom-points/point-management`}
          component={asyncComponent(() =>
            import("./MyKingdom/KingdomPoints/PointManagement")
          )}
        />
        <Route
          path={`${match.url}my-kingdom/kingdom-points/store-history`}
          component={asyncComponent(() =>
            import("./MyKingdom/KingdomPoints/StoreHistory")
          )}
        />
        <Route
          path={`${match.url}my-kingdom/kingdom-points/automatic-points`}
          component={asyncComponent(() =>
            import("./MyKingdom/KingdomPoints/AutomaticPoints")
          )}
        />
        <Route
          path={`${match.url}my-account/kingdom-store`}
          component={asyncComponent(() => import("./MyAccount/KingdomStore"))}
        />
        <Route
          path={`${match.url}my-kingdom/settings`}
          component={asyncComponent(() => import("./MyKingdom/Settings"))}
        />
        <Route
          path={`${match.url}my-kingdom/migrant-dashboard`}
          component={asyncComponent(() =>
            import("./MyKingdom/MigrantDashboard")
          )}
        />
        <Route
          path={`${match.url}admin/admin-dashboard`}
          component={asyncComponent(() => import("./Admin/AdminDashboard"))}
        />
        <Route
          path={`${match.url}admin/kvk-management`}
          component={asyncComponent(() => import("./Admin/KvKManagement"))}
        />
        <Route
          path={`${match.url}admin/kingdom-management`}
          component={asyncComponent(() => import("./Admin/KingdomManagement"))}
        />
        <Route
          path={`${match.url}admin/scans`}
          component={asyncComponent(() => import("./Admin/ScanManager"))}
        />
        <Route
          path={`${match.url}admin/users`}
          component={asyncComponent(() =>
            import("./Admin/ManageUserDashboard")
          )}
        />{" "}
        <Route
          path={`${match.url}admin/bot/status`}
          component={asyncComponent(() => import("./Admin/BotStatus"))}
        />
        <Route
          path={`${match.url}admin/bot/monitor`}
          component={asyncComponent(() => import("./Admin/BotMonitor"))}
        />
        <Route
          path={`${match.url}admin/bot/console`}
          component={asyncComponent(() => import("./Admin/BotConsole"))}
        />
        <Route
          path={`${match.url}admin/player-ids`}
          component={asyncComponent(() => import("./Admin/PlayerIdManagement"))}
        />
        <Route
          path={`${match.url}admin/alliances`}
          component={asyncComponent(() => import("./Admin/AllianceManagement"))}
        />
      </Switch>
    </div>
  );
};

export default App;
