import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faChessRook,
  faCrown,
  faDotCircle,
  faHistory,
  faIdBadge,
  faKhanda,
  faMoneyBillWave,
  faMoneyCheckAlt,
  faPeopleCarry,
  faTable,
  faUser,
  faUserCog,
  faUsers,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

const MenuItemGroup = Menu.ItemGroup;
const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { authUser } = useSelector(({ auth }) => auth);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars
          className="gx-layout-sider-scrollbar"
          style={{ paddingBottom: "50px" }}
        >
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup
              key="my-account-panel"
              className="gx-menu-group"
              title={<IntlMessages id="sidebar.content.header.myAccount" />}
            >
              <Menu.Item key="my-account/profile">
                <Link to="/my-account/profile">
                  <i className="icon icon-user" />
                  <span>
                    <IntlMessages id="sidebar.content.item.myAccount" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="my-account/kingdom-store">
                <Link to="/my-account/kingdom-store">
                  <i className="icon icon-shopping-cart" />
                  <span>
                    <IntlMessages id="sidebar.content.item.kingdomStore" />
                  </span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="my-account/groups">
                <Link to="/my-account/groups">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="icon icon-avatar"
                  />
                  <span>
                    <IntlMessages id="sidebar.content.item.groups" />
                  </span>
                </Link>
              </Menu.Item> */}
            </MenuItemGroup>
            {authUser.rank !== "user" && (
              <MenuItemGroup
                key="my-kingdom-panel"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.content.header.myKingdom" />}
              >
                <Menu.Item key="my-kingdom/dashboard">
                  <Link to="/my-kingdom/dashboard">
                    <i className="icon icon-dasbhoard" />
                    <span>
                      <IntlMessages id="sidebar.content.item.dashboard" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="my-kingdom/kvk-dashboard">
                  <Link to="/my-kingdom/kvk-dashboard">
                    <FontAwesomeIcon
                      icon={faDotCircle}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.kvkDashboard" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="my-kingdom/metrics">
                  <Link to="/my-kingdom/metrics">
                    <i className="icon icon-apps" />
                    <span>
                      <IntlMessages id="sidebar.content.item.metrics" />
                    </span>
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="DKP"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-all-contacts" />
                      <span>
                        <IntlMessages id="sidebar.content.item.menu.kingdomPoints" />
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="my-kingdom/kingdom-points/store-management">
                    <Link to="/my-kingdom/kingdom-points/store-management">
                      <FontAwesomeIcon
                        icon={faMoneyCheckAlt}
                        className="icon icon-avatar"
                      />
                      <span>
                        <IntlMessages id="sidebar.content.item.storeManagement" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="my-kingdom/kingdom-points/point-management">
                    <Link to="/my-kingdom/kingdom-points/point-management">
                      <FontAwesomeIcon
                        icon={faMoneyBillWave}
                        className="icon icon-avatar"
                      />
                      <span>
                        <IntlMessages id="sidebar.content.item.pointManagement" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="my-kingdom/kingdom-points/store-history">
                    <Link to="/my-kingdom/kingdom-points/store-history">
                      <FontAwesomeIcon
                        icon={faHistory}
                        className="icon icon-avatar"
                      />
                      <span>
                        <IntlMessages id="sidebar.content.item.storeHistory" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="my-kingdom/migrant-dashboard">
                  <Link to="/my-kingdom/migrant-dashboard">
                    <FontAwesomeIcon
                      icon={faPeopleCarry}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.migrantDashboard" />
                    </span>
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="kingdomSettings"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-setting" />
                      <span>
                        <IntlMessages id="sidebar.content.item.kingdomSettings" />
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="my-kingdom/settings">
                    <Link to="/my-kingdom/settings">
                      <FontAwesomeIcon
                        icon={faUsersCog}
                        className="icon icon-avatar"
                      />
                      <span>
                        <IntlMessages id="sidebar.content.item.kingdomSettings" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="my-kingdom/kingdom-points/automatic-points">
                    <Link to="/my-kingdom/kingdom-points/automatic-points">
                      <FontAwesomeIcon
                        icon={faCalculator}
                        className="icon icon-avatar"
                      />
                      <span>
                        <IntlMessages id="sidebar.content.item.automaticPoints" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </MenuItemGroup>
            )}
            {authUser.rank === "admin" && (
              <MenuItemGroup
                key="admin-panel"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.content.header.admin" />}
              >
                <Menu.Item key="admin/admin-dashboard">
                  <Link to="/admin/admin-dashboard">
                    <FontAwesomeIcon
                      icon={faTable}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminDashboard" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin/kvk-management">
                  <Link to="/admin/kvk-management">
                    <FontAwesomeIcon
                      icon={faKhanda}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminKvK" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin/kingdom-management">
                  <Link to="/admin/kingdom-management">
                    <FontAwesomeIcon
                      icon={faCrown}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminKingdom" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin/player-ids">
                  <Link to="/admin/player-ids">
                    <FontAwesomeIcon
                      icon={faIdBadge}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminPlayerId" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin/alliances">
                  <Link to="/admin/alliances">
                    <FontAwesomeIcon
                      icon={faChessRook}
                      className="icon icon-avatar"
                    />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminAlliance" />
                    </span>
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="adminServers"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-folder" />
                      <span>
                        <IntlMessages id="sidebar.content.menu.adminServers" />
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="admin/bot/status">
                    <Link to="/admin/bot/status">
                      <i className="icon icon-data-display" />
                      <span>
                        <IntlMessages id="sidebar.content.item.adminServerStatus" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin/bot/monitor">
                    <Link to="/admin/bot/monitor">
                      <i className="icon icon-keyboard" />
                      <span>
                        <IntlMessages id="sidebar.content.item.adminBotMonitor" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item key="admin/scans">
                  <Link to="/admin/scans">
                    <i className="icon icon-chart-composed -flex-column-reverse" />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminScans" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="admin/users">
                  <Link to="/admin/users">
                    <i className="icon icon-avatar -flex-column-reverse" />
                    <span>
                      <IntlMessages id="sidebar.content.item.adminUsers" />
                    </span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
