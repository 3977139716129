const errors = Object.freeze({
  OUT_OF_BALANCE: "OUT_OF_BALANCE",
  SYSTEM_ERROR: "SYSTEM_ERROR",
  DATABASE_ERROR: "DATABASE_ERROR",
  BID_ERROR: "BID_ERROR",
  SALE_ERROR: "SALE_ERROR",
  WS_ERROR: "WS_ERROR",
  JSON_ERROR: "JSON_ERROR",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  UNKNOWN_ITEM: "UNKNOWN_ITEM",
  BID_AMOUNT_ERROR: "BID_AMOUNT_ERROR",
  BID_OPEN_ERROR: "BID_OPEN_ERROR",
  BID_NOT_ALLOWED: "BID_NOT_ALLOWED"
})

module.exports = errors;