import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";

const UserInfo = () => {
  const { authUser, token } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut(token))}>Logout</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        className="gx-avatar gx-pointer"
        style={{
          color: "#f56a00",
          backgroundColor: "#fde3cf",
          fontSize: "18px",
        }}
        alt=""
      >
        {authUser.username[0].toUpperCase()}
      </Avatar>
    </Popover>
  );
};

export default UserInfo;
