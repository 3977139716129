import React, { useEffect } from "react";
let socket;
let isOpen = false;

const useSocket = () => {
  if (isOpen) {
    return socket;
  } else {
    return null;
  }
};

const WebsocketConnect = () => {
  let token = localStorage.getItem("token");

  const jsonMessage = {
    type: "AUTHENTICATION",
    access_token: "Bearer " + token,
  };
  const getStatusJson = {
    type: "GET_STATUS",
  };
  const authorizeSocket = () => {
    socket.onopen = () => {
      isOpen = true;
      socket.send(JSON.stringify(jsonMessage));
      socket.send(JSON.stringify(getStatusJson));
      socketOnClose()
    };
  };
  const socketOnClose = () => {
    socket.onclose = () => {
      isOpen = false;
    };
  };

  useEffect(() => {
    socket = new WebSocket("wss://rok.usa.xtreamdutch-fighters.be:8081/?type=user");
    authorizeSocket();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return <div></div>;
};

export { WebsocketConnect, socket, useSocket };
