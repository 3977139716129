import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Bidding from "./Bidding";

const mainReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    bidding: Bidding,
  });

export default mainReducer;
