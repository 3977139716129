import React, { useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, Popover, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PasswordInput } from "antd-password-input-strength";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./Topbar/languageData";
import { switchLanguage } from "../appRedux/actions/Setting";
import axios from "util/Api";

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const locale = useSelector(({ settings }) => settings.locale);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    // Move away from redux for signup : dispatch(userSignUp(values));
    const { email, password, username, playerid } = values;
    setRegisterSuccess(false);
    setRegisterError(false);
    axios
      .post("auth/register", {
        email: email,
        password: password,
        username: username,
        playerid: playerid,
      })
      .then(({ data }) => {
        console.log("data:", data);
        if (data.status === "success") {
          setRegisterSuccess(true);
          form.resetFields();
        } else {
          setRegisterSuccess(false);
          setRegisterError(true);
        }
      })
      .catch((error) => {
        setRegisterError(true);
      });
  };

  useEffect(() => {
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [authUser, props.history]);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  return (
    <div className="gx-app-login-wrap">
      <Row style={{ height: "100%" }}>
        <Col xl={18} lg={16} md={16} sm={0} xs={0} id="register-left"></Col>
        <Col xl={6} lg={8} md={8} sm={24} xs={24} id="login-right">
          <div className="languageBox">
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-language">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={languageMenu()}
                    trigger="click"
                  >
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                      <i className={`flag flag-24 flag-${locale.icon}`} />
                      &nbsp;{locale.name}
                      <i className="icon icon-chevron-down gx-pl-2"></i>
                    </span>
                  </Popover>
                </span>
              </li>
            </ul>
          </div>
          <div className="center">
            <div className="content">
              <picture>
                <source
                  srcSet="/assets/images/logo.webp"
                  type="image/webp"
                  style={{ marginBottom: "100px" }}
                />
                <img
                  src="/assets/images/logo.png"
                  alt="logo"
                  style={{ marginBottom: "100px" }}
                ></img>
              </picture>
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                className="gx-signin-form gx-form-row0"
                layout="vertical"
                requiredMark={false}
              >
                <h1>
                  <IntlMessages id="signup.form.title" />
                </h1>
                {registerSuccess && (
                  <Alert
                    message={<IntlMessages id="signup.form.alert.success" />}
                    type="success"
                    showIcon
                  />
                )}
                {registerError && (
                  <Alert
                    message={<IntlMessages id="signup.form.alert.error" />}
                    type="error"
                    showIcon
                  />
                )}
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id="signup.form.username.message" />
                      ),
                    },
                  ]}
                  label={<IntlMessages id="signup.form.username" />}
                  name="username"
                >
                  <Input />
                </FormItem>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id="signup.form.playerId.message" />
                      ),
                    },
                  ]}
                  name="playerid"
                  label={<IntlMessages id="signup.form.playerId" />}
                >
                  <Input />
                </FormItem>

                <FormItem
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: <IntlMessages id="signup.form.email.message" />,
                    },
                  ]}
                  label={<IntlMessages id="signup.form.email" />}
                >
                  <Input />
                </FormItem>
                <FormItem
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id="signup.form.password.message" />
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("password").match(
                            /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain minimum eight characters, at least one uppercase letter, one number and one special character!"
                          )
                        );
                      },
                    }),
                  ]}
                  label={<IntlMessages id="signup.form.password" />}
                >
                  <PasswordInput />
                </FormItem>
                <Form.Item
                  name="confirm"
                  label={<IntlMessages id="signup.form.confrimPassword" />}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="signup.form.button.signUp" />
                  </Button>
                  <span>
                    <IntlMessages id="signup.form.or" />
                  </span>{" "}
                  <Link to="/signin">
                    <IntlMessages id="signup.form.button.login" />
                  </Link>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
