/* utils/ga.js */
import GA4React from "ga-4-react";
import { history } from "../appRedux/store";

let ga4react;

export const init = async (G) => {
  if (!GA4React.isInitialized() && G && process.browser) {
    ga4react = new GA4React(G, { debug_mode: true });

    try {
      await ga4react.initialize();

      logPageViews();
    } catch (error) {
      console.error(error);
    }
  } else {
    console.log(G, process.browser, GA4React.isInitialized(), "test");
  }
};

const logPageView = () => {
  ga4react.pageview(window.location.pathname);
};

const logPageViews = () => {
  logPageView();

  history.listen((location, action) => {
    ga4react.pageview(location.pathname);
  });
};

export const logEvent = (action, label, category) => {
  ga4react.event(action, label, category);
};
